<template>
  <div class="home">
    <div class="signpost wood stick-to-floor animate__animated animate__bounceInLeft">
      <router-link to="/portfolio">
        <div title="Portfolio" class="sign sign_longer">
          <span class="sign-text">Portfolio</span>
          <svg viewBox="0 0 180 42" xmlns="http://www.w3.org/2000/svg">
            <path d="m 0 0 h 142 l 31 21 l -31 21 l -142 0 z" />
          </svg>
        </div>
      </router-link>

<!--       <div title="Projects" class="sign">
        <span class="sign-text">Archives</span>
        <svg viewbox="0 0 170 42">
          <path d="m 0 0 h 132 l 31 21 l -31 21 l -132 0 z" />
        </svg>
      </div> -->

      <router-link to="/about">
        <div title="Blog & About me" class="sign">
          <span class="sign-text">About me</span>
          <svg viewBox="0 0 170 42" xmlns="http://www.w3.org/2000/svg">
            <path d="m 0 0 h 132 l 31 21 l -31 21 l -132 0 z" />
          </svg>
        </div>
      </router-link>

      <router-link to="/contact">
        <div title="Contact" class="sign">
          <span class="sign-text">Contact</span>
          <svg viewBox="0 0 170 42" xmlns="http://www.w3.org/2000/svg">
            <path d="m 0 0 h 132 l 31 21 l -31 21 l -132 0 z" />
          </svg>
        </div>
      </router-link>

      <a href="#" @click="openCV">
        <div title="Download CV" class="sign sign_longest">
          <span class="sign-text">Download CV</span>
          <svg viewBox="0 0 190 42" xmlns="http://www.w3.org/2000/svg">
            <path d="m 0 0 h 152 l 31 21 l -31 21 l -152 0 z" />
          </svg>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TextWithGallery from '../components/TextWithGallery.vue'

export default {
  name: 'Home',
  components: {TextWithGallery},

  data () {
    return {

    }
  },

  methods: {
    openCV () {
      window.open('./assets/CV_Jeroen_Bosch.pdf', '_blank');
    }
  },
}
</script>

<style lang="scss">
  @import '@/scss/_variables.scss';
  .home {
    .signpost {
      position: absolute;
      width: scale_width(40);
      height: scale_height(512);
      left: 20%;
      bottom: scale_height(75);

      .sign {
        cursor: pointer;
        position: relative;
        left: scale_width(40);
        width: scale-width(170);
        margin-top: scale_height(20);
        height: scale_height(42);

        &.sign_longer {
          width: scale-width(180);
        }

        &.sign_longest {
          width: scale-width(190);
        }

        .sign-text {
          color: white;
          position: absolute;
          font-family: 'Caveat Brush', cursive;
          left: scale-width(15);
          font-size: scale_width(30);
        }

        svg {
          width: 100%;

          path {
            fill: $wood;
          }
        }
      }
    }
  }
</style>
