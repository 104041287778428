<template>
  <div id="app">
    <div class="clouds decor animate__animated  animate__bounceInDown">
      <div class="cloud cloud1 animate__animated  animate__pulse">
        <div class="cloudpart cloudpart1" />
        <div class="cloudpart cloudpart2" />
        <div class="cloudpart cloudpart3" />
      </div>
      <div class="cloud cloud2 animate__animated  animate__pulse">
        <div class="cloudpart cloudpart1" />
        <div class="cloudpart cloudpart2" />
      </div>
    </div>

    <router-view/>
    <div class="floor decor" />
    <div class="background" />
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Jeroen Bosch - Portfolio',
  },
  data () {
    return {
      age: 28,
      experience: 3,
    }
  },
  methods: {
    openCV () {
      window.open('./assets/CV_Jeroen_Bosch.pdf', '_blank');
    }
  },

  created() {
    document.title = 'Jeroen Bosch - Portfolio';
    document.body.classList.add('fullheight');

    const birthdate = new Date(1992, 9, 5);
    const now = new Date();

    const diffTime = Math.abs(birthdate - now);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    const diffYears = diffDays / 365; 
    const exp = diffDays % 365;

    this.age = Math.floor(diffYears);
    this.experience = exp;
  }
}
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

html, body, .fullheight {
  min-height: 100% !important;
  height: 100%;
}

body {
  margin: 0;
}

h1 {
  font-size: 1.5em;
}

h2 {
  font-size: 1.3em;
}

h3 {
  font-size: 1.1em;
}

figure {
  margin: 0;
}

a {
  color: #BC741F;
}

.regular-padding {
  padding: 15px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  //background: $background;
  //background: linear-gradient(0deg, #ffddb5 0%, #52a9d3 100%);
  min-height: 100%;
}

.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: linear-gradient(0deg, #ffddb5 0%, #52a9d3 100%);
}

.cloud {
  position: absolute;

  animation-iteration-count: infinite;
  animation-duration: 13s !important;

  .cloudpart {
    position: absolute;
    min-width: 10px;
    min-height: 10px;
    background: $cloud-grey;
  }

  &.cloud1 {
    top: scale_height(75);
    left: scale_width(115);

    .cloudpart1 {
      top: 0;
      left: 0;

      width: scale_width(575);
      height: scale_height(225);
    }
    .cloudpart2 {
      top: scale_height(85);
      left: scale_width(220);

      width: scale_width(590);
      height: scale_height(210);
    }
    .cloudpart3 {
      top: scale_height(30);
      left: scale_width(630);

      width: scale_width(370);
      height: scale_height(80);
    }
  }

  &.cloud2 {
    top: scale_height(75);
    left: scale_width(1200);
    animation-duration: 9s !important;

    .cloudpart {
      background: $cloud-grey;
      width: scale_width(448);
      height: scale_height(170);
    }

    .cloudpart1 {
      top: 0;
      left: 0;
    }
    .cloudpart2 {
      top: scale_height(70);
      left: scale_width(110);
    }
  }
}

.floor {
  height: scale_height(75);
  width: 100%;
  background: $floor;
  bottom: 0;
  position: fixed;
}

.stick-to-floor {
  position: fixed;
  bottom: scale_height(75);
}

.wood {
  background: $wood;
}

.decor {
  z-index: 0;
}

.foreground {
  z-index: 10;
}


//////
.scroller {
  overflow-y: auto;
}

.wrapper {
  //max-width: 1600px;
  width: calc(min(1600px, calc(100vw - 530px)));
  min-width: 540px;
  margin: scale_height(30) auto;
  margin-bottom: scale_height(90);
  border-radius: 7.5px;

  @media (max-width: 800px) {
    margin-top: 90px;
  }
}

.blend-white-bg {
  background: rgba(255,255,255,0.75);
}
</style>
