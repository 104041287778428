import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database';

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyAofUFgyJTsTFYL-IbU1dJ1hPpC_Wktm8I",
  authDomain: "jer-one.firebaseapp.com",
  databaseURL: "https://jer-one.firebaseio.com",
  projectId: "Jer-one",
  storageBucket: "jer-one.appspot.com",
  messagingSenderId: "816915768856",
  appId: ''
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const database = firebase.database()

// collection references
// const usersCollection = db.collection('users')
// const postsCollection = db.collection('posts')
// const commentsCollection = db.collection('comments')
// const likesCollection = db.collection('likes')

// export utils/refs
export {
  db,
  auth,
  database,
  // usersCollection,
  // postsCollection,
  // commentsCollection,
  // likesCollection
}