<template>
  <div class="text-gallery-combo">
    <p class="left-align" v-html="text" />
    <div class="thumbnail">
      <img @click="openGallery" :src="thumbnailURL" alt="Open Gallery">
      <p @click="openGallery">Click for more information</p>  
    </div>


    <div class="gallery" :class="{'active': galleryActive}">
      <div class="content">
        <template v-for="(image, i) in gallery">
          <div :class="i === activeIndex ? 'active' : ''" v-bind:key="i">
            <span class="vertical-align-helper"></span>
            <img 
              :src="image.src"
            />
            <p v-if="image.text.length">
              {{image.text}}
            </p>
          </div>
        </template>
        <button @click="nextGalleryItem" class="gallery-previous">&lt;</button>
        <button @click="prevGalleryItem" class="gallery-next">&gt;</button>
      </div>
      <button @click="closeGallery"  class="close-button">X</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'text-with-gallery',
  props: {
    text: String,
    thumbnailURL: String,
    gallery: Array,
  },
  data () {
    return {
      galleryActive: false,
      activeIndex: 0
    }
  },

  methods: {
    openGallery() {
      this.galleryActive = true;
    },

    closeGallery() {
      this.galleryActive = false;
    },

    nextGalleryItem() {
      this.activeIndex++;

      if (this.activeIndex > this.gallery.length-1) {
        this.activeIndex = 0;
      }
    },

    prevGalleryItem() {
      this.activeIndex--;
      if (this.activeIndex < 0) {
        this.activeIndex = this.gallery.length-1;
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.text-gallery-combo {
  display: flex;
  flex-direction: row;
  //padding: 1em;
  flex-wrap: wrap;

  > p {
    width: calc(100% - 450px);
  }

  .thumbnail {
    margin-left: 50px;
    width: 400px;
    height: 400px;
    //background: hsla(0,0%,39.2%,.2);
    position: relative;
    cursor: pointer;

    img {
      max-height: 100%;
      width: 100%;
    }

    p {
      width: calc(100% - 30px);
      position: absolute;
      margin-top: -52px;
      background: rgba(0,0,0,0.5);
      padding: 15px;
      color: white;
    }
  }

  div.gallery {
    &:not(.active) {
      display: none;
    }

    &.active {
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.25);

      .content {
        max-width: 1024px;
        margin: 50px auto;
        height: calc(100% - 100px);

        div:not(.active) {
          display: none;
        }

        div {
          height: 100%;
          background: black;
          position: relative;
          text-align: center;

          .vertical-align-helper {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
          }

          img {
            vertical-align: middle;
            max-width: 100%;
            max-height: 100%;
          }

          p {
            color: white;
            position: absolute;
            top: 60%;
            width: calc(100% - 30px);
            padding: 15px;
            background: rgba(0,0,0,0.5);
          }
        }

        //Controls
        .gallery-next, .gallery-previous {
          position: absolute;
          top: calc(50% - 20px);
          font-size: 100px;
          background: transparent;
          border: none;
          color: white; 
        }
        .gallery-previous {
          left: 0; 
        }
        .gallery-next {
          right: 0; 
        }
      }

      .close-button {
        font-size: 3rem;
        border: none;
        background-color: transparent;
        color: white;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 1em;
        top: 1em;
        z-index: 999
      }
    }
  }
}
</style>
